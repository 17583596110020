// app state
export const APP_STATE_UPDATE = 'APP_STATE_UPDATE'
export const APP_STATE_RESET = 'APP_STATE_RESET'

// brand
export const BRAND_FAILURE = 'BRAND_FAILURE'
export const BRAND_REQUEST = 'BRAND_REQUEST'
export const BRAND_RESET = 'BRAND_RESET'
export const BRAND_SUCCESS = 'BRAND_SUCCESS'

// contact
export const CONTACT_SEND_FAILURE = 'CONTACT_SEND_FAILURE'
export const CONTACT_SEND_REQUEST = 'CONTACT_SEND_REQUEST'
export const CONTACT_SEND_RESET = 'CONTACT_SEND_RESET'
export const CONTACT_SEND_SUCCESS = 'CONTACT_SEND_SUCCESS'

// features
export const FEATURES_LIST_FAILURE = 'FEATURES_LIST_FAILURE'
export const FEATURES_LIST_REQUEST = 'FEATURES_LIST_REQUEST'
export const FEATURES_LIST_SUCCESS = 'FEATURES_LIST_SUCCESS'

export const FEATURE_LOAD_FAILURE = 'FEATURE_LOAD_FAILURE'
export const FEATURE_LOAD_REQUEST = 'FEATURE_LOAD_REQUEST'
export const FEATURE_LOAD_SUCCESS = 'FEATURE_LOAD_SUCCESS'
