import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import Media from 'react-media'

import { FEATURE_LINKS } from 'constants/app'

class Nav extends Component {
  state = {
    isActive: false
  }

  handleNavSelect = () => {
    this.setState({ isActive: false })
  }

  toggleMenu = se => {
    this.setState(prevState => ({
      isActive: !prevState.isActive
    }))
  }

  render() {
    const { isActive } = this.state

    return (
      <Media query="(min-width: 830px)">
        {matches =>
          matches ? (
            <div className="header-nav">{this.renderNav(false)}</div>
          ) : (
            <div className="compact-nav">
              <button
                className={`menu ${isActive ? 'is-active' : ''}`}
                onClick={this.toggleMenu}
                type="button"
                aria-label="Menu"
                aria-controls="compact-nav"
              >
                <i className="material-icons md-36">{isActive ? 'close' : 'menu'}</i>
              </button>
              <nav id="compact-nav" className={isActive ? 'is-active' : ''}>
                {this.renderNav(true)}
              </nav>
            </div>
          )
        }
      </Media>
    )
  }

  renderNav = compact => {
    const { brand, features } = this.props

    return (
      <nav className="nav">
        {features
          .filter(feature => feature.featured)
          .sort((a, b) => a.order - b.order)
          .map(feature => {
            const l = FEATURE_LINKS[feature.code]
            let activeStyle = {}

            if (!compact) {
              const background = l.style ? brand.colors.buttonBackgroundColor : 'inherit'
              const color = l.style ? brand.colors.buttonTextColor : brand.colors.menuTextColor
              activeStyle = { background, borderColor: brand.colors.menuTextSelectedColor, color }
            }

            return (
              <span key={feature.code} className={l.style ? l.style : null}>
                <NavLink
                  to={l.link}
                  activeStyle={activeStyle}
                  onClick={this.handleNavSelect}
                  aria-label={`Visit our ${l.label} page`}
                >
                  <span>{l.label}</span>
                  {compact && <i className="material-icons">keyboard_arrow_right</i>}
                </NavLink>
              </span>
            )
          })}
      </nav>
    )
  }
}

Nav.propTypes = {
  brand: PropTypes.object.isRequired,
  features: PropTypes.array.isRequired
}

export default Nav
