import 'react-app-polyfill/ie9'

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import 'polyfills'
import { loadAppState } from 'actions/app'
import { VANITY_REDIRECT_TOKEN } from 'constants/app'
import { App } from 'containers/'
import store from 'services/store'

const render = Component => {
  ReactDOM.render(
    <Provider store={store}>
      <Component />
    </Provider>,
    document.getElementById('root')
  )
}

const loadApp = () => {
  const { pathname } = window.location
  const isLaunchUrl = pathname.indexOf('/launch') > -1
  const appState = store.getState().app
  const isNewToken = appState.token && isLaunchUrl && pathname.indexOf(`${appState.token}`) === -1
  if ((appState.token && !isLaunchUrl) || (appState.token && isLaunchUrl && !isNewToken)) {
    // use the existing info to load the app
    store.dispatch(loadAppState(appState, isLaunchUrl)).then(() => {
      render(App)
    })
  } else {
    render(App)
  }
}

/*
 * After this site was built, a request came in to provide 'vanity' URL's since
 * the launch URL's are quite lengthy and ugly.
 *
 * This really should be done in a way that doesn't require a release, but this
 * is what I came up with until that can be done.
 */
if (process.env.NODE_ENV === 'production') {
  const subdomain = window.location.hostname.split('.')[0]
  if (VANITY_REDIRECT_TOKEN[subdomain]) {
    window.location.replace(`https://www.clcmembers.net/launch/${VANITY_REDIRECT_TOKEN[subdomain]}`)
  } else {
    loadApp()
  }
} else {
  loadApp()
}
