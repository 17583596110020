import React from 'react'

import { PageTitle } from 'components'

const Accessibility = () => (
  <React.Fragment>
    <PageTitle title="CLC Members - Accessibility Statement" />
    <div className="container-content accessibility">
      <h1>Accessibility Statement</h1>
      <p>
        <span className="basic-information organization-name">CLC</span> is committed to ensuring
        digital accessibility for people with disabilities. We are continually improving the user
        experience for everyone, and applying the relevant accessibility standards.
      </p>
      <h2>Measures to support accessibility</h2>
      <p>
        <span className="basic-information organization-name">CLC</span> takes the following
        measures to ensure accessibility of{' '}
        <span className="basic-information website-name">CLC</span>:
      </p>
      <ul className="organizational-effort accessibility-measures">
        <li>Include accessibility throughout our internal policies.</li>
        <li>Integrate accessibility into our procurement practices.</li>
        <li>Assign clear accessibility targets and responsibilities.</li>
      </ul>
      <h2>Conformance status</h2>
      <p>
        The{' '}
        <a href="https://www.w3.org/WAI/standards-guidelines/wcag/">
          Web Content Accessibility Guidelines (WCAG)
        </a>{' '}
        defines requirements for designers and developers to improve accessibility for people with
        disabilities. It defines three levels of conformance: Level A, Level AA, and Level AAA.{' '}
        <span className="basic-information website-name">CLC</span> is{' '}
        <span className="basic-information conformance-status" data-printfilter="lowercase">
          partially conformant
        </span>{' '}
        with{' '}
        <span className="basic-information conformance-standard">
          <span data-negate="">WCAG 2.0 level AA</span>.
        </span>
        <span>
          <span className="basic-information conformance-status">Partially conformant</span> means
          that{' '}
          <span className="basic-information conformance-meaning">
            some parts of the content do not fully conform to the accessibility standard
          </span>
          .
        </span>
      </p>
      <h2>Feedback</h2>
      <p>
        We welcome your feedback on the accessibility of{' '}
        <span className="basic-information website-name">CLC</span>. Please let us know if you
        encounter accessibility barriers on{' '}
        <span className="basic-information website-name">CLC</span>:
      </p>
      <ul className="basic-information feedback h-card">
        <li>
          Phone: <span className="phone-number p-tel">(800) 541-9701</span>
        </li>
        <li>
          E-mail:{' '}
          <a className="email u-email" href="mailto:accessibility@clcadvantage.com">
            accessibility@clcadvantage.com
          </a>
        </li>
        <li>
          Visitor address:{' '}
          <span className="visitor-address p-extended-address">
            3001 Lava Ridge Ct, Suite 235, Roseville, CA 95661
          </span>
        </li>
      </ul>
      <h2>Technical specifications</h2>
      <p>
        Accessibility of <span className="basic-information website-name">CLC</span>
        relies on the following technologies to work with the particular combination of web browser
        and any assistive technologies or plugins installed on your computer:
      </p>
      <ul className="technical-information technologies-used">
        <li>HTML</li>
        <li>WAI-ARIA</li>
        <li>CSS</li>
        <li>JavaScript</li>
      </ul>
      <p>
        These technologies are relied upon for conformance with the accessibility standards used.
      </p>
      <h2>Assessment approach</h2>
      <p>
        <span className="basic-information organization-name">CLC</span> assessed the accessibility
        of <span className="basic-information website-name">CLC</span> by the following approaches:
      </p>
      <ul className="technical-information assessment-approaches">
        <li>Self-evaluation</li>
      </ul>
      <hr />
      <p>
        This statement was created on{' '}
        <span className="basic-information statement-created-date">July 1, 2019</span> using the{' '}
        <a href="https://www.w3.org/WAI/planning/statements/">
          W3C Accessibility Statement Generator Tool
        </a>
        .
      </p>
    </div>
  </React.Fragment>
)

export default Accessibility
