import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'

import { loadFeatureIfNeeded } from 'actions/features'
import { logRequest } from 'actions/requests'
import { PageTitle } from 'components'

// feature: id_theft / id_theft_victim
class IdentityTheft extends Component {
  state = {
    description: ''
  }

  componentWillMount() {
    const { currentFeature, feature, loadFeatureIfNeeded, logRequest } = this.props

    logRequest({
      feature,
      category: undefined,
      item: undefined,
      action: 'view'
    })

    if (!currentFeature) {
      loadFeatureIfNeeded(feature)
    }
  }

  render() {
    const { brand, currentFeature } = this.props

    return (
      <React.Fragment>
        <PageTitle
          title={
            currentFeature && currentFeature.title
              ? `CLC - Members - ${currentFeature.title}`
              : 'CLC Members'
          }
        />
        <div className="feature identity-theft">
          <div className="banner">
            <p>{currentFeature && currentFeature.summary}</p>
          </div>
          <div className="container">
            <h1 style={{ color: brand.colors.headerTextColor }}>
              {currentFeature && currentFeature.title}
            </h1>
            <div className="container-content">
              {/* {description && ReactHtmlParser(description)} */}
              {currentFeature && ReactHtmlParser(currentFeature.description)}
              {currentFeature && !currentFeature.parentId && (
                <p>
                  Are you a victim of Identity Theft?{' '}
                  <Link to="/identity-theft-victim-info">This article</Link> describes how to know
                  what to do next.
                </p>
              )}
            </div>
            {currentFeature && !currentFeature.parentId && (
              <div>
                <p className="action">To get started, give us a call.</p>
                <a
                  href={`tel: +1-${brand.phone}`}
                  className="button phone"
                  style={{
                    color: brand.colors.buttonTextColor,
                    backgroundColor: brand.colors.buttonBackgroundColor
                  }}
                >
                  {brand.phone}
                </a>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

IdentityTheft.propTypes = {
  brand: PropTypes.object.isRequired,
  currentFeature: PropTypes.object,
  feature: PropTypes.string.isRequired
}

const mapStateToProps = (state, props) => {
  const currentFeature = state.features.list.length
    ? state.features.list.find(f => f.code === props.feature)
    : null
  return {
    brand: state.brand,
    currentFeature
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loadFeatureIfNeeded,
      logRequest
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(IdentityTheft)
