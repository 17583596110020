import { clcApi } from 'services/apis'

export const logRequest = event => {
  return async (dispatch, getState) => {
    const { referenceIds } = getState().app

    const payload = { event, referenceIds }
    const [error] = await clcApi.post('requests', payload)

    if (error) {
      console.error('Request Logrror', error)
    }
  }
}
