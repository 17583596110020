import React from 'react'

const NotFound = () => (
  <div className="not-found">
    <h1>Sorry</h1>
    <p>We couldn't find that page. Use the menu above to navigate our site.</p>
  </div>
)

export default NotFound
