export function loadState() {
  try {
    const serializedState = localStorage.getItem('state')
    if (serializedState === null) {
      return undefined
    }
    const state = JSON.parse(serializedState)
    return state.app ? { ...state, app: JSON.parse(atob(state.app)) } : state
  } catch (error) {
    return undefined
  }
}

export function saveState(state) {
  try {
    const serializedState = state.app ? { ...state, app: btoa(JSON.stringify(state.app)) } : state
    localStorage.setItem('state', JSON.stringify(serializedState))
  } catch (error) {
    console.error(error)
  }
}

export function clearState() {
  try {
    localStorage.removeItem('state')
  } catch (error) {
    console.error(error)
  }
}
