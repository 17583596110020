import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import ReactHtmlParser from 'react-html-parser'
import cheerio from 'cheerio'

import { logRequest } from 'actions/requests'
import { PageTitle } from 'components'

// feature: local_attorneys
class Attorneys extends Component {
  state = {
    description: ''
  }

  componentWillMount() {
    const { currentFeature, feature, logRequest } = this.props

    logRequest({
      feature,
      category: undefined,
      item: undefined,
      action: 'view'
    })

    if (currentFeature && currentFeature.description) {
      const $ = cheerio.load(currentFeature.description, {
        xmlMode: true
      })
      $('p')
        .last()
        .remove()
      this.setState({ description: $.html() })
    }
  }

  render() {
    const { brand, currentFeature } = this.props
    const { description } = this.state

    return (
      <React.Fragment>
        <PageTitle
          title={
            currentFeature && currentFeature.title
              ? `CLC - Members - ${currentFeature.title}`
              : 'CLC Members'
          }
        />
        <div className="feature attorneys">
          <div className="banner">
            <p>{currentFeature && currentFeature.summary}</p>
          </div>
          <div className="container">
            <h1 style={{ color: brand.colors.headerTextColor }}>
              {currentFeature && currentFeature.title}
            </h1>
            <div className="container-content">{description && ReactHtmlParser(description)}</div>
            <p className="action">
              To get started, give us a call and we will match you to the most appropriately skilled
              attorney in your area.
            </p>
            <a
              href={`tel: +1-${brand.phone}`}
              className="button phone"
              style={{
                backgroundColor: brand.colors.buttonBackgroundColor,
                color: brand.colors.buttonTextColor
              }}
            >
              {brand.phone}
            </a>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

Attorneys.propTypes = {
  brand: PropTypes.object.isRequired,
  currentFeature: PropTypes.object.isRequired,
  feature: PropTypes.string.isRequired
}

const mapStateToProps = (state, props) => {
  const currentFeature = state.features.list.length
    ? state.features.list.find(f => f.code === props.feature)
    : null
  return {
    brand: state.brand,
    currentFeature
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      logRequest
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Attorneys)
