import to from 'await-to-js'
import store from 'services/store'

export default class Api {
  static timeout = 120000

  static headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }

  constructor(props) {
    const { baseUrl, headers, includeBearer } = props
    this.baseUrl = baseUrl
    this.headers = {
      ...Api.headers
    }
    this.includeBearer = includeBearer

    if (headers) {
      Object.keys(headers).forEach(key => {
        if (headers[key] === null) {
          delete this.headers[key]
        } else {
          this.headers[key] = headers[key]
        }
      })
    }
  }

  request = (url, options) => {
    options = { ...options, timeout: Api.timeout }

    // use the stored token
    const tokenId = store.getState().app.token

    if (this.includeBearer) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${tokenId}`
      }
    }

    // see https://www.npmjs.com/package/await-to-js
    return to(
      fetch(url, options).then(
        async response => {
          if (response.ok) {
            if (response.status !== 204) {
              if (response.headers.get('content-type').startsWith('application/json')) {
                return await response.json()
              } else {
                return await response.text()
              }
            }
          } else {
            const apiError = new Error('Api Error')
            apiError.response = response
            throw apiError
          }
        },
        () => {
          throw new Error('Call Error')
        }
      )
    )
  }

  get = url => {
    const { headers, request, baseUrl } = this
    const options = {
      headers,
      credentials: 'include',
      method: 'GET',
      mode: 'cors'
    }

    // add a cache-busting param for GET requests
    // url += (url.indexOf('?') > -1 ? '&_=' : '?_=') + Date.now()

    return request(`${baseUrl}/${url}`, options)
  }

  post = (url, data) => {
    const { headers, request, baseUrl } = this
    const options = {
      headers,
      credentials: 'include',
      body: this.headers['Content-Type'] === 'application/json' ? JSON.stringify(data) : data,
      method: 'POST',
      mode: 'cors'
    }

    return request(`${baseUrl}/${url}`, options)
  }

  put = (url, data) => {
    const { headers, request, baseUrl } = this
    const options = {
      headers,
      credentials: 'include',
      body: this.headers['Content-Type'] === 'application/json' ? JSON.stringify(data) : data,
      method: 'PUT',
      mode: 'cors'
    }

    return request(`${baseUrl}/${url}`, options)
  }

  delete = url => {
    const { headers, request, baseUrl } = this
    const options = {
      headers,
      credentials: 'include',
      method: 'DELETE',
      mode: 'cors'
    }

    return request(`${baseUrl}/${url}`, options)
  }
}
