import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { logRequest } from 'actions/requests'
import history from 'services/history'

class ExternalLink extends Component {
  componentWillMount() {
    const { currentFeature, feature, logRequest } = this.props
    logRequest({
      feature,
      category: undefined,
      item: undefined,
      action: 'view'
    })

    window.open(currentFeature.link, '_blank')
    history.replace('/')
  }

  render() {
    return <div />
  }
}

ExternalLink.propTypes = {
  brand: PropTypes.object.isRequired,
  currentFeature: PropTypes.object.isRequired,
  feature: PropTypes.string.isRequired
}

const mapStateToProps = (state, props) => {
  const currentFeature = state.features.list.length
    ? state.features.list.find(f => f.code === props.feature)
    : null
  return {
    brand: state.brand,
    currentFeature
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      logRequest
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(ExternalLink)
