import {
  CONTACT_SEND_FAILURE,
  CONTACT_SEND_REQUEST,
  CONTACT_SEND_RESET,
  CONTACT_SEND_SUCCESS
} from 'constants/actionTypes'
import { FAILURE, SUCCESS } from 'constants/app'

const initialState = {
  isSending: false,
  message: null,
  status: null
}

export default function contact(state = initialState, action) {
  switch (action.type) {
    case CONTACT_SEND_FAILURE:
      return {
        ...state,
        message: action.message,
        status: FAILURE
      }
    case CONTACT_SEND_REQUEST:
      return {
        ...state,
        isSending: true
      }
    case CONTACT_SEND_RESET:
      return initialState
    case CONTACT_SEND_SUCCESS:
      return {
        ...state,
        message: action.message,
        status: SUCCESS
      }
    default:
      return state
  }
}
