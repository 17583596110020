import { BRAND_FAILURE, BRAND_REQUEST, BRAND_RESET, BRAND_SUCCESS } from 'constants/actionTypes'
import { FAILURE, SUCCESS } from 'constants/app'

const initialState = {
  colors: {},
  isFetching: false,
  logoUri: '',
  message: '',
  status: null,
  title: ''
}

export default function brand(state = initialState, action) {
  switch (action.type) {
    case BRAND_FAILURE:
      return {
        ...state,
        isFetching: false,
        status: FAILURE
      }
    case BRAND_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case BRAND_RESET:
      return initialState
    case BRAND_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
        status: SUCCESS
      }
    default:
      return state
  }
}
