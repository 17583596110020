import {
  FEATURES_LIST_FAILURE,
  FEATURES_LIST_REQUEST,
  FEATURES_LIST_SUCCESS,
  FEATURE_LOAD_FAILURE,
  FEATURE_LOAD_REQUEST,
  FEATURE_LOAD_SUCCESS
} from 'constants/actionTypes'

import filter from 'lodash/filter'

import { FEATURE_LINKS } from 'constants/app'
import { clcApi } from 'services/apis'

export const loadFeature = code => {
  return async dispatch => {
    dispatch({
      type: FEATURE_LOAD_REQUEST
    })

    const [error, feature] = await clcApi.get(`features/${code}`)

    if (!error) {
      dispatch({
        type: FEATURE_LOAD_SUCCESS,
        item: feature
      })
    } else {
      dispatch({
        type: FEATURE_LOAD_FAILURE,
        message: error
      })
    }
  }
}

export const loadFeatureIfNeeded = code => {
  return async (dispatch, getState) => {
    const features = getState().features

    if (features.list.length) {
      const feature = features.list.find(f => f.code === code)

      if (!feature) {
        return dispatch(loadFeature(code))
      }
    }
  }
}

export const loadFeatures = () => {
  return async dispatch => {
    dispatch({
      type: FEATURES_LIST_REQUEST
    })

    const [error, features] = await clcApi.get('features?activeOnly=true')

    if (!error) {
      dispatch({
        type: FEATURES_LIST_SUCCESS,
        list: filter(features, feature => FEATURE_LINKS.hasOwnProperty(feature.code))
      })
    } else {
      dispatch({
        type: FEATURES_LIST_FAILURE,
        message: error
      })
    }
  }
}

export const loadFeaturesIfNeeded = () => {
  return async (dispatch, getState) => {
    const features = getState().features

    if (!features.list.length) {
      return dispatch(loadFeatures())
    }
  }
}
