// redux-logger is a middleware that lets you log every state change
import { createLogger } from 'redux-logger'

// redux-thunk is a middleware that lets you dispatch async actions
import thunk from 'redux-thunk'
import { createStore, applyMiddleware } from 'redux'

import rootReducer from 'reducers/'
import { loadState } from 'services/localStorage'

const middlewares = []
middlewares.push(thunk)

if (process.env.NODE_ENV !== 'production') {
  middlewares.push(createLogger({ collapsed: true }))
}

const middleware = applyMiddleware(...middlewares)

const persistedState = loadState()
let initialState = {}
if (persistedState && persistedState.token) {
  initialState = {
    app: {
      orgId: persistedState.orgId,
      referenceIds: persistedState.referenceIds,
      token: persistedState.token
    }
  }
}
const store = createStore(rootReducer, initialState, middleware)

export default store
