import 'styles/app.css'

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Router, Route, Switch } from 'react-router-dom'

import {
  Accessibility,
  Footer,
  Header,
  NotAuthorized,
  NotFound,
  ScrollToTop,
  Terms
} from 'components/'
import { Attorneys, ExternalLink, Home, IdentityTheft, Launch, Mediators } from 'containers/'
import history from 'services/history'

const withProps = (Component, props) => {
  return function(matchProps) {
    return <Component {...props} {...matchProps} />
  }
}

class App extends Component {
  render() {
    const { app, brand, features } = this.props

    return (
      <Router history={history}>
        <ScrollToTop>
          <div className="clc-container">
            <div id="skip">
              <a href="#content">Skip to main content</a>
            </div>
            <Switch>
              <Route path="/launch/:token" component={Launch} />
              <Route
                path="/"
                render={props => {
                  if (!app || (app && !app.token)) {
                    return <NotAuthorized />
                  } else {
                    return (
                      <React.Fragment>
                        <Header brand={brand} features={features} />
                        <div className="clc-content" id="clc-content">
                          <div className="content" id="content">
                            <Switch>
                              <Route
                                path="/attorneys"
                                component={withProps(Attorneys, {
                                  feature: 'local_attorneys'
                                })}
                              />
                              <Route
                                exact
                                path="/identity-theft"
                                component={withProps(IdentityTheft, {
                                  feature: 'id_theft'
                                })}
                              />
                              <Route
                                exact
                                path="/identity-theft-victim-info"
                                component={withProps(IdentityTheft, {
                                  feature: 'id_theft_victim'
                                })}
                              />
                              <Route
                                exact
                                path="/library"
                                component={withProps(ExternalLink, {
                                  feature: 'legal_library'
                                })}
                              />
                              <Route
                                exact
                                path="/forms"
                                component={withProps(ExternalLink, {
                                  feature: 'legal_forms'
                                })}
                              />
                              <Route
                                exact
                                path="/mediators"
                                component={withProps(Mediators, {
                                  feature: 'local_mediators'
                                })}
                              />
                              <Route
                                exact
                                path="/identity-protect"
                                component={withProps(ExternalLink, {
                                  feature: 'identity_protect'
                                })}
                              />
                              <Route path="/accessibility" component={Accessibility} />
                              <Route path="/terms" component={Terms} />
                              <Route path="/" exact component={Home} />
                              <Route path="*" component={NotFound} />
                            </Switch>
                          </div>
                          <Footer />
                        </div>
                      </React.Fragment>
                    )
                  }
                }}
              />
            </Switch>
          </div>
        </ScrollToTop>
      </Router>
    )
  }
}

App.propTypes = {
  app: PropTypes.object.isRequired,
  brand: PropTypes.object.isRequired,
  features: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
  app: state.app,
  brand: state.brand,
  features: state.features.list
})

export default connect(mapStateToProps)(App)
