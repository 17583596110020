export const FEATURE_LINKS = {
  legal_forms: { link: '/forms', label: 'Forms' },
  legal_library: { link: '/library', label: 'Library' },
  local_attorneys: { link: '/attorneys', label: 'Attorneys' },
  local_mediators: { link: '/mediators', label: 'Mediators' },
  id_theft: { link: '/identity-theft', label: 'ID Theft' },
  identity_protect: { link: '/identity-protect', label: 'ID Scan' }
}

export const ROOT_PHONE_NUMBER = '888-724-2326'

export const FAILURE = 'FAILURE'
export const SUCCESS = 'SUCCESS'

export const PHONE_MASK = [
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/
]

export const VANITY_REDIRECT_TOKEN = {
  bestbenefits: '34c5d830-8daa-11eb-ae52-ea63beea6ebb',
  unionplus: 'ecfca9d0-3ca6-11e9-9fa9-022c6d769bc5',
  google: '46100780-6095-11e9-ab48-8963789f0545'
}
