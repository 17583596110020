import { combineReducers } from 'redux'

import app from './app'
import brand from './brand'
import contact from './contact'
import features from './features'

const rootReducer = combineReducers({
  app,
  brand,
  contact,
  features
})

export default rootReducer
