import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { FEATURE_LINKS } from 'constants/app'
import { PageTitle } from 'components'

// TODO sort/filter should be moved out of render
class Home extends Component {
  render() {
    const { brand, features } = this.props
    const featured = features.list.filter(f => f.featured)

    return (
      <React.Fragment>
        <PageTitle title="CLC Members - Home" />
        <div className="home">
          <h1 style={{ color: brand.colors.headerTextColor }}>{brand.title}</h1>
          <p>{brand.message}</p>
          <div className="feature-container">
            {featured
              .sort((a, b) => a.order - b.order)
              .map(feature => (
                <div className="feature" key={feature.code}>
                  <div>
                    <Link to={FEATURE_LINKS[feature.code].link}>
                      <img src={feature.image} alt={`Visit ${feature.title}`} />
                      <h1 className="title">{feature.title}</h1>
                    </Link>
                  </div>
                  <p>{feature.summary}</p>
                </div>
              ))}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

Home.propTypes = {
  brand: PropTypes.object.isRequired,
  features: PropTypes.object.isRequired
}

const mapStateToProps = state => {
  return {
    brand: state.brand,
    features: state.features
  }
}

export default connect(mapStateToProps)(Home)
