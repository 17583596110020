import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { launchApp } from 'actions/app'

class Launch extends Component {
  componentWillMount() {
    const {
      launchApp,
      location: { search },
      match: {
        params: { token }
      }
    } = this.props
    launchApp(token, search)
  }

  render() {
    return (
      <div className="loading">
        <img src="/img/loading.gif" alt="Loading" />
      </div>
    )
  }
}

Launch.propTypes = {
  launchApp: PropTypes.func.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      launchApp
    },
    dispatch
  )

export default connect(null, mapDispatchToProps)(Launch)
