import { Component } from 'react'
import { withRouter } from 'react-router'

class ScrollToTop extends Component {
  content = null

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      if (!this.content) {
        this.content = document.getElementById('clc-content')
      }

      if (this.content) {
        this.content.scrollTop = 0
      }
    }
  }

  render() {
    return this.props.children
  }
}

export default withRouter(ScrollToTop)
