import React from 'react'

import { PageTitle } from 'components'

const Terms = () => (
  <React.Fragment>
    <PageTitle title="CLC Members - Privacy and Terms" />
    <div className="container-content terms">
      <h1>Privacy and Terms of Use</h1>

      <h2>Our Privacy Policy</h2>
      <p>Effective Date: January 15, 2020</p>

      <p>
        Keeping member information private is a priority for CLC and its affiliates. To enable us to
        provide our membership plans, we need to collect certain personal information from you. This
        Privacy Policy describes how personal information is collected, used, shared and protected,
        which choices you have relating to your personal data, and how you can contact us.
      </p>

      <p>
        It is sometimes necessary to share personal information with partners to provide our
        services to you. All partners/individuals with access to personal information about our
        members are required to follow this Privacy Policy and maintain the privacy of personal
        information in accordance with law.
      </p>

      <h3>Information We Collect</h3>
      <p>
        CLC is the sole owner of the information collected on this website. We will not sell, share,
        or rent this information to others in ways different from what is disclosed in this policy.
        CLC collects information from users at several different points on our website.
      </p>

      <h3>Use of Cookies</h3>
      <p>
        A “cookie” is a computer file that serves as an identification card for your computer
        browser to distinguish itself from others. Our website uses cookies to recognize and track
        your preferences. In addition, some of CLC’s partners use cookies on our website.{' '}
      </p>
      <p>
        Most web browsers accept cookies automatically. You may be able to reject and/or delete
        cookies in your browser’s settings. For further instructions, check your browser’s technical
        information. However, if you choose to reject or delete cookies, then this may disable or
        interfere with features of the CLC website, in addition to other websites you visit. Usage
        of a cookie is in no way linked to personally identifiable information while on CLC’s
        website.
      </p>

      <h3>Log Files</h3>
      <p>
        We use IP addresses to analyze trends, administer the site, and gather broad demographic
        information for aggregate use. IP addresses are not linked to personally identifiable
        information.
      </p>

      <h3>Information We Use</h3>
      <p>We may use your personal information for the following purposes:</p>
      <p>
        (‘Collected Information’) refers to personal information that a user has provided to CLC.
      </p>
      <ol type="A">
        <li>
          For Contact Purposes: We may use your Collected Information to contact you and to respond
          to your requests. We may also use your Collected Information to communicate with you on an
          as-needed basis, including but not limited to email or landline.{' '}
        </li>
        <li>
          For Confirming Identity: We may use your Collected Information to confirm your identity.
          This is to help detect security breaches, and protect against malicious, deceptive or
          illegal activity.
        </li>
        <li>
          For Announcements: We may, to the extent permitted by law, use your Collected Information
          to notify you of CLC offerings, marketing and promotional materials on our behalf. You
          have the ability to opt-out of announcement material at any time by unsubscribing from our
          email list.
        </li>
      </ol>

      <h3>Information We Share</h3>
      <p>
        We share aggregated information with our partners who provide specific services on behalf of
        CLC. When a member registers for these services, we will share names, or other contact
        information that is necessary for our partner to provide these services. When information is
        shared with our partners, we protect against subsequent disclosure of that information with
        a confidentiality agreement and/or other protective measures.{' '}
      </p>
      <p>
        In no event do we disclose your personal information to companies that will use that
        information to contact you about their own products or services.
      </p>

      <h3>Links</h3>
      <p>
        CLC’s website contains links to other sites. Please be aware that we are not responsible for
        the privacy practices of other sites.
      </p>

      <h3>Our Security Procedures</h3>
      <p>
        We restrict access to non-public personal information to those persons who need such
        information to provide products or services to you. We maintain physical, electronic, and
        procedural safeguards that comply with federal regulations to protect your non-public
        personal information. We keep information for as long as is necessary for our business
        purposes and will dispose of all information in appropriate ways according to law and our
        company practices.
      </p>

      <h3>Changes to Privacy Policy</h3>
      <p>
        CLC reserves the right, in its sole discretion, to revise, change or modify this Privacy
        Policy at any time. Any material changes to the Privacy Policy will be incorporated on this
        page. Notice of changes may be given in any manner we choose in compliance with the law such
        as, but not limited to, posting a notice of changes on our websites. We encourage you to
        review this page periodically for the most recent version of the Privacy Policy.{' '}
      </p>

      <h3>California Consumer Privacy Act</h3>
      <p>
        Consumers in California may have certain privacy rights, including the right to request to
        know what personal information CLC has collected about them, the deletion of such
        information, and how CLC has used or otherwise shared such information over the past years.
        California consumers can make a request by contacting the following email address{' '}
        <a href="mailto:CCPA@clchomeoffice.com">CCPA@clchomeoffice.com</a>, or by calling
        1-800-451-5297. CLC will respond within 45 days of receiving a verified consumer request.
        CLC will not respond to requests if it cannot verify or otherwise confirm the identity of
        the person making the request.
      </p>
      <p>
        CLC collects various types of personal information, as explained elsewhere in this policy,
        for the purpose of facilitating the services purchased by members. Categories of information
        collected include, but are not limited to, personally identifiable information, such as
        names, addresses, and social security numbers; commercial information; and legal
        information. CLC does not sell any of this personal information to advertisers or third
        parties. However, CLC does share personal information with certain affiliates, including
        provider law firms, identity theft monitors, and fraud resolution specialists, in
        circumstances when sharing is needed to fulfill the services purchased by members. In these
        situations, such personal data is exempt from deletion requests due to the necessity of the
        information to provide services.
      </p>

      <h3>Questions?</h3>
      <p>
        If you have any questions about this Privacy Policy or if you would like to review or make
        changes to your personal information, please email us at{' '}
        <a href="mailto:privacy@clcadvantage.com">privacy@clcadvantage.com</a>
      </p>
    </div>
  </React.Fragment>
)

export default Terms
