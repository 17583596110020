import {
  FEATURES_LIST_FAILURE,
  FEATURES_LIST_REQUEST,
  FEATURES_LIST_SUCCESS,
  FEATURE_LOAD_FAILURE,
  FEATURE_LOAD_REQUEST,
  FEATURE_LOAD_SUCCESS
} from 'constants/actionTypes'
import { FAILURE, SUCCESS } from 'constants/app'

const initialState = {
  isFetching: false,
  list: [],
  message: null,
  status: null
}

export default function features(state = initialState, action) {
  switch (action.type) {
    case FEATURES_LIST_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: action.message,
        status: FAILURE
      }
    case FEATURES_LIST_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case FEATURES_LIST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        list: action.list,
        status: SUCCESS
      }
    case FEATURE_LOAD_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: action.message,
        status: FAILURE
      }
    case FEATURE_LOAD_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case FEATURE_LOAD_SUCCESS:
      return {
        ...state,
        isFetching: false,
        list: [...state.list, action.item],
        status: SUCCESS
      }
    default:
      return state
  }
}
