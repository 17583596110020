import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => (
  <footer className="clc-footer">
    <div className="footer">
      <div className="links">
        <span>
          <Link to="/">Home</Link>
        </span>
        <span>
          <Link to="/terms">Terms / Privacy</Link>
        </span>
        <span>
          <Link to="/accessibility">Accessibility Statement</Link>
        </span>
      </div>
      <div className="copyright">&copy;{new Date().getFullYear()} CLC Incorporated</div>
    </div>
  </footer>
)

export default Footer
