import React from 'react'
import Helmet from 'react-helmet'

const PageTitle = ({ title }) => {
  const defaultTitle = 'CLC Members - Home'
  return (
    <Helmet>
      <title>{title ? title : defaultTitle}</title>
    </Helmet>
  )
}

export default PageTitle
