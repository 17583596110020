import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { Nav } from 'components/'

const Header = ({ brand, features }) => (
  <header className="clc-header">
    <div className="header">
      <div className="header-logo">
        <Link to="/" aria-label="Return to the Home page">
          <img src={brand.logoUri} alt="CLC Members Home" />
        </Link>
      </div>
      <Nav brand={brand} features={features} />
    </div>
  </header>
)

Header.propTypes = {
  brand: PropTypes.object.isRequired,
  features: PropTypes.array.isRequired
}

export default Header
